<template>
  <div class="form mb-12">
    <form action="#" @submit.prevent="triggerFetchCandidate()">
      <div class="inline-block">
        <input
          class="inline-block"
          type="text"
          id="period-name"
          placeholder="Cari nama calon peserta didik"
          v-model="q"
        />
      </div>
      <button class="button --primary inline-block ml-4" type="submit">
        <fa-icon icon="search"></fa-icon>
      </button>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.form {
  input,
  label {
    @apply block;
  }
}
</style>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      q: "",
    };
  },
  methods: {
    async triggerFetchCandidate() {
      let filter = `?q=${this.q}`;
      await this.fetchCandidates(filter);
    },

    ...mapActions("candidate", ["fetchCandidates"]),
  },
  name: "SearchForm",
};
</script>